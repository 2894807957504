<template>
  <v-app>

    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b">
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Daftar Perjanjian <b>{{ currentDoctor.name }}</b>
      </div>
    </b-alert>

    <div class="row">

      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <b-tabs
              class="mt-n3"
              content-class="mt-3">
              <b-tab
                title="Kalender"
                active>
                <Calendar
                  :isMyAppointment="true"
                  :currentDoctor="currentDoctor" />
              </b-tab>
              <b-tab title="Perjanjian">
                <Table
                  :items="items"
                  :itemExcel="itemExcel"
                  :itemPopUp="itemPopUp"
                  :fields="fields"
                  :isMyAppointment="true"
                  :perPage="perPage"
                  :currentPage="currentPage"
                  :totalRows="totalRows"
                  :tabFiled="tabFiled"
                  @appointmentOnStatusChange="pagination"
                  @filter="generalFilter"
                  @filterByPatient="generalFilter"
                  @pageOnClick="pageOnClick"
                  @btnExportOnClick="btnExportOnClick" />
              </b-tab>
            </b-tabs>
          </template>
        </Card>
      </div>

    </div>

  </v-app>
</template>

<script>

import Card from '@/view/content/Card.vue'
import Table from '@/component/appointments/Table.vue'
import Calendar from '@/component/doctors/Calendar.vue'
import module from '@/core/modules/CrudModule.js'
import { mapGetters } from 'vuex'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Table,
    Calendar
  },

  data() {
    return {
      filter: {
        appointment_status_id: '',
        start_date: '',
        end_date: '',
        patient_name: '',
      },
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "patient_name",
          label: "Pasien",
          sortable: true,
        },
        {
          key: "display_date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "time",
          label: "Waktu",
          sortable: true,
        },
        {
          key: "action_type_name",
          label: "Jenis Tindakan",
          sortable: true,
        },
        {
          key: "appointment_status_name",
          label: "Status",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      items: [],
      itemExcel: [],
      itemPopUp: [],
      tabFiled: 0,
    }
  },

  methods: {

    pageOnClick(page) {
      this.currentPage = page
      this.pagination()
    },

    async pagination() {
      let filterParams = `&appointment_status_id=${this.filter.appointment_status_id}&doctor_id=${this.currentDoctor.id}&patient_name=${this.filter.patient_name}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`
      let response = await module.paginate('appointments', `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      let pagination = response.meta.pagination
      this.items = response.data
      this.totalRows = pagination.total
    },

    async getExport() {
      const excelRoute = 'appointment-excel'
      const popUpRoute = 'appointment-no-paginations'
      let filterParams = `&appointment_status_id=${this.filter.appointment_status_id}&patient_name=${this.filter.patient_name}&doctor_id=${this.currentDoctor.id}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`
      let responseExcel = await module.get(excelRoute, `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      let responsePopUp = await module.get(popUpRoute, `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      this.itemExcel = responseExcel
      this.itemPopUp = responsePopUp
    },

    setFilter(sent) {
      for (const key in this.filter) {
        this.filter[key] = sent[key]
      }
      if (
        this.filter.patient_name != '' ||
        this.filter.appointment_status_id != '' ||
        this.filter.start_date != '' ||
        this.filter.end_date != ''
      )
        this.currentPage = 1
    },

    async generalFilter(sent) {
      this.setFilter(sent)
      this.pagination()
    },

    async btnExportOnClick(filter) {
      this.setFilter(filter)
      await this.getExport()
    }

  },

  computed: {
    ...mapGetters({
      currentDoctor: 'currentUserPersonalInfo'
    })
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dokter", route: "" },
      { title: "Perjanjian Saya" }
    ])
    this.pagination()
  }

}
</script>